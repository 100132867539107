import { createAction, props } from '@ngrx/store';

export const setWAlert = createAction(
  "[W Alert] set w alert",
  props<{ wAlert: any }>()
);

export const clearWAlert = createAction("[W Alert] clear w alert");

export const setWAlertStatus = createAction(
  "[W Alert] set w alert status",
  props<{ status: any }>()
);