import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CodeModel } from '@app/models/system/code.model';
import { IpModel } from '@app/models/system/ip.model';
 
@Injectable({
  providedIn: 'root'
})
export class IpService {

  constructor(private _http: HttpClient) { }

  getLocation(){
    return this._http.get<IpModel>('http://api.ipify.org/?format=json');
  }

  
}