import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthUtil } from '@app/common/util/auth.util';
import { ConvertUtil } from '@app/common/util/convert.util';
import { IpModel } from '@app/models/system/ip.model';
import { ValidationUtil } from '@app/common/util/validation.util';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  ip: IpModel = {} as IpModel;
  constructor(private _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const isLogined = AuthUtil.isLogined();
    // const url = state.url;
    // this.ip = AuthUtil.getIp();
    // if(ValidationUtil.isNotNullAndNotEmpty(this.ip)){
    //   if(url.includes('shopping-mall') || url == '/'){
    //     if(ConvertUtil.convertToSring(this.ip.ip) != '115.79.42.202'&& ConvertUtil.convertToSring(this.ip.ip) != '58.233.160.140'){
    //       if (!isLogined) {
    //         this._router.navigate(["/login"]);
    //       }else{
    //         this._router.navigate(["/my-office"]);
    //       }
    //     }else{
    //       return true;
    //     }
    //   }
    // }
    if (!isLogined) {
      this._router.navigate(["/login"]);
    }
    return isLogined;
  }
}
