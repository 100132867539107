<div class="step-content agreement col">
    <div class="info">
        <div class="info-content">
            <div>
                <p style="margin-left:0cm; margin-right:0cm; text-align:center"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><em>CONSENT TO ELECTRONIC RECORD</em></strong></span></span></p>

                    <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">Before entering into this agreement or any other online agreement with Erom Plus USA, you must consent to the use of an electronic record of the Agreement.&nbsp; Please read the following information carefully.</span></span></p>

                    <ol>
                        <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">1.  This consent applies to all agreements that you may enter into with Erom Plus USA that are evidenced by an electronic record. The agreement that you currently are entering into with Erom Plus USA will be evidenced by an electronic record. &nbsp;&nbsp;&nbsp;</span></span></li>
                        <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">2.  To view the terms of any such agreement and submit the same to Erom Plus USA, you will need a Personal Computer (&ldquo;PC&rdquo;), laptop computer, tablet, or smart phone with Internet access, operational Internet browser software, and Portable Document Format (PDF) reader software.&nbsp; </span></span></li>
                        <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">3.  Your consent will apply to this and all transactions between you and Erom Plus USA.</span></span></li>
                        <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">4.  You may withdraw your consent to the use of electronic records at any time.&nbsp; Should you do so, any agreement between you and Erom Plus USA will be automatically terminated and you will lose all rights under such agreement(s). Should you wish to withdraw your consent to the exclusive use of an electronic agreement (and thereby terminate the agreement), or update any personal information and you are an Erom Plus USA Distributor, you may do so through your Erom Plus USA My Office or by contacting us at <a name="_Hlk147327647"></a><a href="mailto:Admin@eromplus-usa.com" style="color:blue; text-decoration:underline">Admin@eromplus-usa.com</a> or 866-866-1004. If you are not an Erom Plus USA Distributor, you may do so by logging into your Erom Plus USA account (if you are a Member) or by contacting us at <a name="_Hlk147327699"></a><a href="mailto:Admin@eromplus-usa.com" style="color:blue; text-decoration:underline">Admin@eromplus-usa.com</a> or 866-866-1004.</span></span></li>
                        <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">5.  If you need a paper copy of the terms of any agreement between you and Erom Plus USA and you are an Erom Plus USA Distributor, you may download a current version via your My Office.&nbsp; Alternatively, or if you are not an Erom Plus USA Distributor, you may send an e-mail requesting these documents to <a href="mailto:Admin@eromplus-usa.com" style="color:blue; text-decoration:underline">Admin@eromplus-usa.com</a>. If the agreement that you would like a copy of has been amended or updated, you may request an archived version. Erom Plus USA will then send you an email with the requested documents attached.&nbsp; If you would like printed copies mailed to you, you will be charged a fee of<span style="font-size:8.0pt"><a class="msocomanchor" href="#_msocom_1" id="_anchor_1" name="_msoanchor_1" style="color:blue; text-decoration:underline"></a>&nbsp;</span><span style="font-size:8.0pt"></span> $3 per page.&nbsp; </span></span></li>
                        <li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">6.  If there ever is a change in the equipment or software necessary to access any agreement that you have with Erom Plus USA, Erom Plus USA will advise you of the same and will provide you with a list of the equipment and software that is necessary. Upon such event, you may voluntarily terminate your Agreement with Erom Plus USA.&nbsp;&nbsp; </span></span></li>
                    </ol>

                    <p style="margin-left:0cm; margin-right:0cm; text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">By entering into an agreement with Erom Plus USA or subscribing to an Erom Plus USA product or service, you consent to the use of electronic records evidencing your agreement(s) with Erom Plus USA.&nbsp; </span></span></p>
                    <div>
                    <hr />
                    </div>

            </div>
        </div>
    </div>
<div>