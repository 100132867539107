
import { ValidationUtil } from '@app/common/util/validation.util';
import { environment } from '@enviroments/environment';
import { LangConstant } from '../constant/lang.constant';
import { AuthUtil } from './auth.util';
import { ConvertUtil } from './convert.util';
import { MyProgram } from '@app/models/system/my-program.model';
import { Node } from '@app/models/components/node.model';

export class CommonUtils {

  static getMessages(): string {
    return 'messages.' + CommonUtils.getLangMessage();
  }

  static getLangMessage(): string {
    let res = environment.default_lang.toLowerCase();
    let lang = CommonUtils.getLang();

    switch (lang) {
      case 'kr':
        res = 'kr';
        break;
      case 'en':
        res = 'en';
        break;
      case 'us':
        res = 'en';
        break;
      default:
        res = 'en';
        break;
    }

    return res;
  }

  static getLang(): string {
    let loginedInfo = AuthUtil.getLoginedInfo();
    let lang = sessionStorage.getItem(LangConstant.STR_LANG);

    if (ValidationUtil.isNullOrEmpty(lang)) {
      if (loginedInfo) {
        lang = ConvertUtil.convertToSring(loginedInfo.ctrCd);
      } else {
        lang = environment.default_lang;
      }
      sessionStorage.setItem(LangConstant.STR_LANG, lang);
    }

    return ConvertUtil.convertToSring(lang).toLowerCase();
  }

  // static isCompanyUS(){
  //   return CompanyConstant.COM_ID_MEDIUS == environment.comId //TODO
  // }
  static isLangUS() {
    return (CommonUtils.getLang() == 'en' || CommonUtils.getLang() == 'EN');
  }

  static plus(number1 : any, number2 : any) : number {
    return Number(number1) + Number(number2);
  }

  static minus(number1 : any, number2 : any) : number {
    return Number(number1) - Number(number2);
  }

  static getListFromCurrentMonth(currentMonth : number) {
    let totalMonth = 12 - currentMonth;
    if(totalMonth === 0 || currentMonth > 12 || currentMonth <= 0) {
      return Array.from({length : 12}, (e, i) => ConvertUtil.convertToZeroDecimal(CommonUtils.plus(i, 1)));
    }
    return Array.from({length: totalMonth}, (e, i) => ConvertUtil.convertToZeroDecimal(CommonUtils.plus(currentMonth, i + 1)));
  }

  static getListFromCurrentYear(currentYear : number) {
    return Array.from({length: 5}, (e, i) => {
      return ConvertUtil.convertToZeroDecimal(CommonUtils.plus(currentYear, i));
    });
  }

  static mapObject<TSource extends {}, TTarget>(source : TSource) : TTarget {
    let result : TTarget = {} as TTarget;
    return Object.assign(source, result);
  }

  /**
   * the function will set menu lv 1 to main menu, menu lv 2 to subMenu and child of main menu
   * @param myProgram MyProgram[]
   * @param menu Map<string, Node<MyProgram>>
   * @param subMenu MyProgram[]
   * @returns the object include menu (Main menu) and subMenu
   */
  static setDataSubMenuAndMain(myProgram : MyProgram[], menu : Map<string, Node<MyProgram>>, subMenu : MyProgram[]) : {menu : Map<string, Node<MyProgram>>, subMenu : MyProgram[]} {
    myProgram.forEach(v => {
      if(v.menuLv == '1') {
        menu.set(v.prgId, {data : v, children : []});
      }

      if(v.menuLv == '2') {
        const node = menu.get(v.pid);
        if (node !== undefined && node?.children !== undefined && node?.children !== null) {
          node.children.push({data : v, children : []});
        }
        subMenu.push(v);
      }
    });

    return {
      menu : menu,
      subMenu : subMenu
    }
  }

 static omitSpecialChar(event:any , kind:string) : any{
    const inputChar = event.target.value;
    let pattern = /^[a-zA-Z]*$/;
    let replaceStr = /[^a-zA-Z]/g;
    if(kind == 'num'){
      pattern = /^[0-9]*$/;
      replaceStr = /[^0-9]/g;
    }
    if(kind == 'space'){
      pattern = /^[a-zA-Z\s]*$/;
      replaceStr = /[^a-zA-Z\s]/g;
    }
    if(kind == 'com'){
      pattern = /^[0-9a-zA-Z\s]*$/;
      replaceStr = /[^0-9a-zA-Z\s]/g;
    }
    if (!pattern.test(inputChar)) {
      // Nếu ký tự không phải là chữ cái tiếng Anh, hủy sự kiện và không cho phép nhập
      return event.target.value.replace(replaceStr, '');
    } else {
      // Nếu là chữ cái tiếng Anh, cập nhật giá trị
      return inputChar;
    }

  }

  static omitSpecialCharUpd(event: any, kind: string, component: any, variableName: string): any {
    let inputValue = event.target.value;
    let replaceStr = /[^a-zA-Z]/g;

    if (kind === 'num') {
      replaceStr = /[^0-9]/g;
    } else if (kind === 'space') {
      replaceStr = /[^a-zA-Z\s]/g;
    } else if (kind === 'com') {
      replaceStr = /[^0-9a-zA-Z\s]/g;
    }

    // Thay thế các ký tự không hợp lệ
    inputValue = inputValue.replace(replaceStr, '');

    // Cập nhật giá trị của trường nhập liệu
    event.target.value = inputValue;

    // Cập nhật giá trị cho ngModel tương ứng
    component[variableName] = inputValue;

    return inputValue;
  }

 static formatPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }

    // Loại bỏ tất cả các ký tự không phải là số
    const cleaned = phoneNumber.replace(/[^0-9]/g, '');

    // Kiểm tra độ dài chuỗi sau khi loại bỏ
    if (cleaned.length !== 10) {
      return 'Invalid phone number';
    }

    // Định dạng lại chuỗi
    const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    return formatted;
  }

}

