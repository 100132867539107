<!doctype html>
<html lang="ko">
 <head>
  <meta charset="UTF-8">
  <meta name="Generator" content="EditPlus®">
  <meta name="Author" content="">
  <meta name="Keywords" content="">
  <meta name="Description" content="">
  <title>f1099-nec</title>
  <style>
	@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
	body{padding:0;margin:0;font-size:12px;line-height:1.1;}
	li{list-style:none;}
	ul,h1,h2,h3,p,li,dt,dd,table,th,dt,dd,dl,ol{padding:0;margin:0;}
	caption{display:none;}
	h1, h2, h3, h4, h5, dl, dt, dd, ul, li, ol, th, td, p, blockquote, form, fieldset, legend, div,body {	-webkit-print-color-adjust:exact;	}
	.clr:after{content:"";display:block;clear:both;}
	.printWrap{width:100%;margin:0 auto; font-size:10px;font-family: 'Roboto', sans-serif;}
	table{border-collapse:collapse;}
	.printWrap input[type="checkbox"]{margin:0;vertical-align:middle;background:#fff;border:1px solid #141414;}
	label.label{display:inline-block;margin-right:20px;position:relative;padding-left:21px;cursor:pointer;}
	label.label input{position:absolute;z-index:-1;opacity:0;display: none;}
	.mark{position:absolute;top:50%;margin-top:-8px;left:0;height:16px;width:16px;border:1px solid #888;box-sizing:border-box;}
	.radio .mark{border-radius:50%;}
	label.label input:checked ~ .mark{background:#1761fd;border:1px solid #1761fd;}
	label.label input:disabled ~ .mark{background:#e6e6e6;opacity:0.6;pointer-events:none;}
	.mark:after{content:'';position:absolute;display:none;}
	label.label input:checked ~ .mark:after{display:block;}
	.mark.on:after{display:block;}
	.mark.on{background: #1761fd;border: 1px solid #1761fd;}
	.checkbox .mark:after{left:5px;top:2px;width:3px;height:7px;border:solid #fff;border-width:0 2px 2px 0;transform:rotate(45deg);}
	.checkbox input:disabled ~ .mark:after{border-color:#1761fd;}
	.radio .mark:after{left:3px;top:3px;height:8px;width:8px;border-radius:50%;background:#fff;}
	.radio input:disabled ~ .mark:after{background:#1761fd;}
	.cnterInput{padding-left: 0 !important;width: 100%;}
	.cnterInput .mark{left: 50%;margin-left: -8px;}

	.printWrap .f1099Top{display:flex;font-size: 14px;margin-bottom: 3px;}
	.printWrap .f1099Top li:nth-child(1){color:#000;width:25%;padding-left: 85px;box-sizing: border-box;}
	.printWrap .f1099Top li:nth-child(2){width:90px;}
	.printWrap .f1099Top li input{border-radius: 0;}
	.f1099Wrap{display:flex;}
	.tableWrap:last-child{width:calc(50% + 18px);}
	.tableWrap:first-child{border:2px solid #000;width:calc(50% - 18px);}
	.tableWrap:first-child table tr:last-child{height: 36px;}
	.tableWrap:first-child table tr:last-child td{vertical-align: top;}
	.tableWrap table td{border-right:2px solid #000;border-bottom:2px solid #000;padding: 0;}
	.tableWrap table td:last-child{border-right: 0;}
	.tableWrap table tr:last-child td{border-bottom: 0;}
	.tableWrap table td h3{font-size:10px;padding: 3px;}
	.tableWrap table td h3 b{padding-left: 3px;font-weight: 600;}
	.tableWrap table td .dataArea{min-height:13px;padding: 3px;color:#000;}
	.tableWrap table td .dataArea.row2{height:26px;}
	.tableWrap table td .dataArea.row5{height:26px;}
	.tableWrap table td span.dataArea{display:block;background: transparent;}
	.mgAuto{margin:0 auto !important;display: block;}
	.tableWrap table td.bgTd{background: #c0c0c0;}
	.tableWrap table td.bgTd h3{display: inline-block;background-color: #fff;padding-right: 5px;}
	.t-r{text-align: right;}
	.t-c{text-align: center;}
	.mgr0{margin-right: 0 !important;}
	.pdl0{padding-left: 0 !important;}
	.bdr{border-right:2px solid #000 !important;}
	.bdb{border-bottom: 2px solid #000 !important;}
	.bdbDashed td{border-bottom: 1px dashed #000 !important;}
	.bdt{border-top: 2px solid #000 !important;}
	.bdb0{border-bottom: 0 !important;}
	.printWrap h1{font-size: 14px;margin-bottom: 5px;color: #000;}
	.printWrap h2{font-size:12px;color: #000;}
	.printWrap td p{letter-spacing: -0.5px;}
	.dpInline{display:inline !important;}
	.tbTopUl{height: 47px;display: flex;flex-direction: column;justify-content: space-between;}
	.widthBtn{position: relative;}
	.widthBtn h3{display:inline-block;width: calc(100% - 22px);letter-spacing: -0.3px;}
	.widthBtn label{position: absolute;top:50%;right: 3px;margin-top: -8px !important;}
	.widthBtn label .mark{position: unset;margin-top: 0;}
	.f1099Btm{display:flex;justify-content: space-between;align-items: center;margin-bottom:30px;}
	.endTxt{letter-spacing: 1.62px;word-spacing: 1px;}
	.dollar{font-weight: 500;font-size: 12px;}
	.bdLine{position: relative;}
	.bdLine::after{content: "";display: block;width: 100%;height: 100%;border:2px solid #000;position: absolute;top: 0;left: 0;box-sizing: border-box;}
	.vtcalTop{vertical-align: top;}
	.printBtmTxt{display:flex;width:100%;flex-wrap: wrap;}
	.printBtmTxt h2{width:100%;}
	.printBtmTxt > div{flex:1;}
	.printBtmTxt > div:first-child{padding-right:10px;}
	.printBtmTxt > div:last-child{padding-left:10px;}
    .txt{font-size: 14px}
  </style>
 </head>
 <body>
	<div class="printWrap">
		<ul class="f1099Top">
			<li></li>
			<li>
			</li>
			<li>
				<label class="label checkbox">CORRECTED (if checked)
					<input type="checkbox">
					<div class="mark"></div>
				</label>
			</li>
		</ul>
		<div class="f1099Wrap">
			<div class="tableWrap">
				<table>
					<colgroup>
						<col width="50%" />
						<col width="*" />
						<col width="63px" />
					</colgroup>
					<tbody>
						<tr>
							<td colspan="3">
								<h3>PAYER’S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.</h3>
								<div class="dataArea row5 txt">EROM, INC.</div>
                                <div class="dataArea txt">14630 INDUSTRY CIR <br>LA MIRADA, CA 90638</div>               
							</td>
						</tr>
						<tr>
							<td>
								<h3>PAYER’S TIN</h3>
								<div class="dataArea txt">20-0109739</div>
							</td>
							<td colspan="2">
								<h3>RECIPIENT’S TIN</h3>
								<div class="dataArea txt">{{memInfo.deJuminNoAll}}</div>
							</td>
						</tr>
						<tr>
							<td colspan="3" class="bdb0">
								<h3>RECIPIENT’S name</h3>
								<div class="dataArea row2 txt">{{memInfo.username}}</div>
							</td>
						</tr>
						<tr>
							<td colspan="3" class="bdb0">
								<h3>Street address (including apt. no.)</h3>
								<div class="dataArea txt">{{memInfo.addr1 + (memInfo.addr2 != null?', '+memInfo.addr2:'')}}</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<h3>City or town, state or province, country, and ZIP or foreign postal code</h3>
								<div class="dataArea txt">{{memInfo.city + ', ' + memInfo.state + ' ' + memInfo.post}}</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<h3>Account number (see instructions)</h3>
								<div class="dataArea"></div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="tableWrap">
				<table>
					<colgroup>
						<col width="37%" />
						<col width="95px" />
						<col width="*" />
					</colgroup>
					<tbody>
						<tr style="height:55px">
							<td rowspan="2" class="bgTd bdt"></td>
							<td class="t-c bdt">
								<ul class="tbTopUl">
									<li>OMB No. 1545-0116</li>
									<li>Form <h1 class="dpInline">1099-NEC</h1></li>
									<li>(Rev. January 2022)</li>
								</ul>
							</td>
							<td rowspan="2" class="t-r"><h1>Nonemployee Compensation</h1></td>
						</tr>
						<tr style="height:36px;">
							<td class="t-c bdr bdb">
								<ul>
									<li>For calendar year</li>
									<li>20<span>____</span></li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
				<table>
					<colgroup>
						<col width="29%" />
						<col width="*" />
						<col width="32%" />
					</colgroup>
					<tbody>
						<tr>
							<td colspan="2">
								<h3><b>1</b> Nonemployee compensation</h3>
								<div class="dataArea">
									<span class="dollar">$</span>
									<span class="txt">{{memInfo.totalAmt | setComma:'price'}}</span>
								</div>
							</td>
							<td rowspan="4" class="t-r">
								<h1>Copy B</h1>
								<h2>For Recipient</h2>
								<p>This is important tax
									information and is being
									furnished to the IRS. If you are
									required to file a return, a
									negligence penalty or other
									sanction may be imposed on
									you if this income is taxable
									and the IRS determines that it
									has not been reported.</p>
							</td>
						</tr>
						<tr>
							<td colspan="2" class="widthBtn bdr">
								<h3><b>2</b> Payer made direct sales totaling $5,000 or more of consumer products to recipient for resale</h3>
								<label class="label checkbox mgr0 pdl0">
									<input type="checkbox">
									<div class="mark"></div>
								</label>
							</td>
						</tr>
						<tr>
							<td colspan="2" class="bgTd bdr">
								<h3><b>3</b></h3>
								<span class="dataArea"></span>
							</td>
						</tr>
						<tr>
							<td colspan="2" class="bdr bdLine">
								<h3><b>4</b> Federal income tax withheld</h3>
								<div class="dataArea">
									<span class="dollar">$</span>		
                                    <span class="txt">0.00</span>							
								</div>
							</td>
						</tr>
						<tr class="bdbDashed">
							<td>
								<h3><b>5</b> State tax withheld</h3>
								<div class="dataArea">
									<span class="dollar">$</span>									
								</div>
							</td>
							<td>
								<h3><b>6</b> State/Payer’s state no</h3>
								<div class="dataArea"></div>
							</td>
							<td>
								<h3><b>7</b> State income</h3>
								<div class="dataArea">
									<span class="dollar">$</span>									
								</div>
							</td>
						</tr>
						<tr class="bdb">
							<td>
								<div class="dataArea">
									<span class="dollar">$</span>									
								</div>
							</td>
							<td>
								<div class="dataArea"></div>
							</td>
							<td>
								<div class="dataArea">
									<span class="dollar">$</span>									
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<ul class="f1099Btm">
			<li>Form <h1 class="dpInline">1099-NEC</h1> (Rev. 1-2022)</li>
			<li>(keep for your records)</li>
			<li>www.irs.gov/Form1099NEC</li>
			<li>Department of the Treasury - Internal Revenue Service</li>
		</ul>


		<ul class="f1099Top">
			<li></li>
			<li>
				<label class="label checkbox">VOID
					<input type="checkbox">
					<div class="mark"></div>
				</label>
			</li>
			<li>
				<label class="label checkbox">CORRECTED
					<input type="checkbox">
					<div class="mark"></div>
				</label>
			</li>
		</ul>
		<div class="f1099Wrap">
			<div class="tableWrap">
				<table>
					<colgroup>
						<col width="50%" />
						<col width="*" />
						<col width="63px" />
					</colgroup>
					<tbody>
						<tr>
							<td colspan="3">
								<h3>PAYER’S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.</h3>
								<div class="dataArea row5 txt">EROM, INC.</div>
                                <div class="dataArea txt">14630 INDUSTRY CIR<br>LA MIRADA, CA 90638</div>
							</td>
						</tr>
						<tr>
							<td>
								<h3>PAYER’S TIN</h3>
								<div class="dataArea txt">20-0109739</div>
							</td>
							<td colspan="2">
								<h3>RECIPIENT’S TIN</h3>
								<div class="dataArea txt">{{memInfo.deJuminNoAll}}</div>
							</td>
						</tr>
						<tr>
							<td colspan="3" class="bdb0">
								<h3>RECIPIENT’S name</h3>
								<div class="dataArea row2 txt">{{memInfo.username}}</div>
							</td>
						</tr>
						<tr>
							<td colspan="3" class="bdb0">
								<h3>Street address (including apt. no.)</h3>
								<div class="dataArea txt">{{memInfo.addr1 + (memInfo.addr2 != null?', '+memInfo.addr2:'')}}</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<h3>City or town, state or province, country, and ZIP or foreign postal code</h3>
								<div class="dataArea txt">{{memInfo.city + ', ' + memInfo.state + ' ' + memInfo.post}}</div>
							</td>
						</tr>
						<tr>
							<td colspan="3">
								<h3>Account number (see instructions)</h3>
								<div class="dataArea"></div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="tableWrap">
				<table>
					<colgroup>
						<col width="37%" />
						<col width="95px" />
						<col width="*" />
					</colgroup>
					<tbody>
						<tr style="height:55px">
							<td rowspan="2" class="bgTd bdt"></td>
							<td class="t-c bdt">
								<ul class="tbTopUl">
									<li>OMB No. 1545-0116</li>
									<li>Form <h1 class="dpInline">1099-NEC</h1></li>
									<li>(Rev. January 2022)</li>
								</ul>
							</td>
							<td rowspan="2" class="t-r"><h1>Nonemployee Compensation</h1></td>
						</tr>
						<tr style="height:36px;">
							<td class="t-c bdr bdb">
								<ul>
									<li>For calendar year</li>
									<li>20<span>____</span></li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
				<table>
					<colgroup>
						<col width="29%" />
						<col width="*" />
						<col width="32%" />
					</colgroup>
					<tbody>
						<tr>
							<td colspan="2">
								<h3><b>1</b> Nonemployee compensation</h3>
								<div class="dataArea">
									<span class="dollar">$</span>
									<span class="txt">{{memInfo.totalAmt | setComma:'price'}}</span>
								</div>
							</td>
							<td rowspan="4" class="t-r vtcalTop">
								<h1>Copy 2</h1>
								<h2>To be filed with<br>
									recipient’s state<br>
									income tax<br>
									return, when<br>
									required.</h2>
							</td>
						</tr>
						<tr>
							<td colspan="2" class="widthBtn bdr">
								<h3><b>2</b> Payer made direct sales totaling $5,000 or more of consumer products to recipient for resale</h3>
								<label class="label checkbox mgr0 pdl0">
									<input type="checkbox">
									<div class="mark"></div>
								</label>
							</td>
						</tr>
						<tr>
							<td colspan="2" class="bgTd bdr">
								<h3><b>3</b></h3>
								<span class="dataArea"></span>
							</td>
						</tr>
						<tr>
							<td colspan="2" class="bdr">
								<h3><b>4</b> Federal income tax withheld</h3>
								<div class="dataArea">
									<span class="dollar">$</span>		
                                    <span class="txt">0.00</span>							
								</div>
							</td>
						</tr>
						<tr class="bdbDashed">
							<td>
								<h3><b>5</b> State tax withheld</h3>
								<div class="dataArea">
									<span class="dollar">$</span>									
								</div>
							</td>
							<td>
								<h3><b>6</b> State/Payer’s state no</h3>
								<div class="dataArea"></div>
							</td>
							<td>
								<h3><b>7</b> State income</h3>
								<div class="dataArea">
									<span class="dollar">$</span>									
								</div>
							</td>
						</tr>
						<tr class="bdb">
							<td>
								<div class="dataArea">
									<span class="dollar">$</span>									
								</div>
							</td>
							<td>
								<div class="dataArea"></div>
							</td>
							<td>
								<div class="dataArea">
									<span class="dollar">$</span>									
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<ul class="f1099Btm">
			<li>Form <h1 class="dpInline">1099-NEC</h1> (Rev. 1-2022)</li>
			<li>www.irs.gov/Form1099NEC</li>
			<li>Department of the Treasury - Internal Revenue Service</li>
		</ul>

		<div class="printBtmTxt">
			<h2>Instructions for Recipient</h2>
			<div>
				<ul>
					<li>You received this form instead of Form W-2 because the payer did not
						consider you an employee and did not withhold income tax or social
						security and Medicare tax.</li>
					<li style="text-indent:10px;">If you believe you are an employee and cannot get the payer to correct
						this form, report the amount shown in box 1 on the line for “Wages,
						salaries, tips, etc.” of Form 1040, 1040-SR, or 1040-NR. You must also
						complete Form 8919 and attach it to your return. For more information, see
						Pub. 1779, Independent Contractor or Employee.</li>
					<li style="text-indent:10px;">If you are not an employee but the amount in box 1 is not selfemployment (SE) income (for example, it is income from a sporadic activity
						or a hobby), report the amount shown in box 1 on the “Other income” line
						(on Schedule 1 (Form 1040)).</li>
					<li><b>Recipient’s taxpayer identification number (TIN).</b> For your protection, this
						form may show only the last four digits of your TIN (social security number
						(SSN), individual taxpayer identification number (ITIN), adoption taxpayer
						identification number (ATIN), or employer identification number (EIN)).
						However, the issuer has reported your complete TIN to the IRS.</li>
					<li><b>Account number.</b> May show an account or other unique number the payer
						assigned to distinguish your account.</li>
					<li><b>Box 1.</b> Shows nonemployee compensation. If the amount in this box is SE
						income, report it on Schedule C or F (Form 1040) if a sole proprietor, or on
						Form 1065 and Schedule K-1 (Form 1065) if a partnership, and the
						recipient/partner completes Schedule SE (Form 1040).</li>
				</ul>
			</div>
			<div>
				<ul>
					<li><b>Note:</b> If you are receiving payments on which no income, social security,
						and Medicare taxes are withheld, you should make estimated tax
						payments. See Form 1040-ES (or Form 1040-ES (NR)). Individuals must
						report these amounts as explained in these box 1 instructions.
						Corporations, fiduciaries, and partnerships must report these amounts on
						the appropriate line of their tax returns.</li>
					<li><b>Box 2.</b> If checked, consumer products totaling $5,000 or more were sold
						to you for resale, on a buy-sell, a deposit-commission, or other basis.
						Generally, report any income from your sale of these products on
						Schedule C (Form 1040).</li>
					<li><b>Box 3.</b> Reserved for future use.</li>
					<li><b>Box 4.</b> Shows backup withholding. A payer must backup withhold on
						certain payments if you did not give your TIN to the payer. See Form W-9,
						Request for Taxpayer Identification Number and Certification, for
						information on backup withholding. Include this amount on your income
						tax return as tax withheld.</li>
					<li><b>Boxes 5–7.</b> State income tax withheld reporting boxes.</li>
					<li><b>Future developments.</b> For the latest information about developments
						related to Form 1099-NEC and its instructions, such as legislation enacted
						after they were published, go to www.irs.gov/Form1099NEC.</li>
					<li><b>Free File Program.</b> Go to www.irs.gov/FreeFile to see if you qualify for
						no-cost online federal tax preparation, e-filing, and direct deposit or
						payment options.</li>
				</ul>
			</div>
		</div>
	</div><!-- //printWrap -->
 </body>
</html>
