import { createAction, props } from '@ngrx/store';

export const setItem = createAction(
  "[Item] set item",
  props<{ item: any }>()
);

export const setParamItem = createAction(
  "[Item] set param item",
  props<{ itemParam: any }>()
);

export const checkItem = createAction(
  "[Item] set param item",
  props<{ checkItem: boolean }>()
);