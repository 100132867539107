import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-electronic-record-popup',
  templateUrl: './electronic-record-popup.component.html',
  styleUrls: ['./electronic-record-popup.component.css']
})
export class ElectronicRecordPopupComponent implements OnInit {

  html:any =''

  constructor() { }

  ngOnInit(): void {
  }

}
