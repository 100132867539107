import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { CommonUtils } from './common/util/common.util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'medius-shop';

  constructor(
    private _translate: TranslateService,
    ) { 
  }

  ngOnInit(): void {
    let messages = CommonUtils.getMessages();
    this._translate.use(messages);

    let currentURL = window.location.href;
    const characterToRemove = '/?';
    if(currentURL.includes("?brand") || currentURL.includes("?shopping") || currentURL.includes("?eromian") || currentURL.includes("?event") || currentURL.includes("?magazine") || currentURL.includes("?service")){
      window.location.href = decodeURIComponent(currentURL.replace(characterToRemove, '/'));
    }
  }
}
