import { Component, OnInit } from '@angular/core';
import { loadMemInfo } from '@app/actions/myoffice/member/member-infor-inquiry.actions';
import { loadUserProfile } from '@app/actions/myoffice/user-profile/user-profile.actions';
import { AuthUtil } from '@app/common/util/auth.util';
import { ConvertUtil } from '@app/common/util/convert.util';
import { ValidationUtil } from '@app/common/util/validation.util';
import { MemInfo } from '@app/models/myoffice/member/mem-info.model';
import { Member } from '@app/models/myoffice/member/member.model';
import { MemberInfoInquiryState, getMemInfo } from '@app/selectors/myoffice/member/member-infor-inquiry.selector';
import { UserProfileState, getUserProfileItems } from '@app/selectors/myoffice/user-profile/user-profile.selector';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nec-report',
  templateUrl: './nec-report.component.html',
  styleUrls: ['./nec-report.component.css']
})
export class NecReportComponent implements OnInit {

  personalInfo$ = new Observable<Member>;
  personalInfo: Member = {} as Member;

  userid: string = '';

  memInfo$ = new Observable<MemInfo>;
  memInfo: MemInfo = {} as MemInfo;

  constructor(
    private _personalInfoStore: Store<UserProfileState>,
    private _memberInfoStore: Store<MemberInfoInquiryState>,
  ) {
    this.personalInfo$ = this._personalInfoStore.select(getUserProfileItems);
    this.memInfo$ = this._memberInfoStore.select(getMemInfo);
   }

  ngOnInit(): void {
    let member = AuthUtil.getLoginedInfo();
    if (member) {
      this.userid = ConvertUtil.convertToSring(member.userid);

      this._memberInfoStore.dispatch(loadMemInfo(this.userid));
    }

    this.memInfo$.subscribe(res => {
      if(ValidationUtil.isAnyNotNullAndNotEmpty(res)){
        this.memInfo = res;

        setTimeout(() => window.print(), 200);
      }
    })

  }

}
