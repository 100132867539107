import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';

import { loadCompany } from '@app/actions/system/company.actions';
import { Company } from '@app/models/system/company.model';
import { getOrderInfosReport, getOrderMstReport, getOrderProductsReport, OrderReportState } from '@app/selectors/myoffice/report/order-report.selector';
import { CompanyState, getCompany } from '@app/selectors/system/company.selector';
import { OrderMstModel } from '@app/models/myoffice/order/order-mst.model';
import { OrderProductReportModel } from '@app/models/myoffice/report/order-product.model';
import { ConvertUtil } from '@app/common/util/convert.util';
import { ValidationUtil } from '@app/common/util/validation.util';
import { loadOrderInfoReport, loadOrderMstReport, loadOrderProductReport } from '@app/actions/myoffice/report/order-report.action';
import { getMemInfo, MemberInfoInquiryState } from '@app/selectors/myoffice/member/member-infor-inquiry.selector';
import { MemInfo } from '@app/models/myoffice/member/mem-info.model';
import { loadMemInfo } from '@app/actions/myoffice/member/member-infor-inquiry.actions';
import { environment } from '@enviroments/environment';
import { SmWownetModel } from '@app/models/system/sm-wownet.model';
import { getSmWownet, getSmWownetConfig, getWowWord, SmWownetState } from '@app/selectors/system/sm-wownet.selector';
import { loadSmWownet, loadSmWownetConfig, loadWowWord } from '@app/actions/system/sm-wownet.action';
import { CommonUtils } from '@app/common/util/common.util';
import { getOrderDelis, getOrderInfo, getOrderPayInfo, getOrderProductInfo, OrderDetailInquiryState, sumOrderProductInfo } from '@app/selectors/myoffice/order/order-detail-inquiry.selector';
import { OrderPdtModel } from '@app/models/myoffice/order/order-pdt.model';
import { loadDeliOrd, loadOrderPay, loadOrderProduct, loadSumOrderProduct } from '@app/actions/myoffice/order/order-detail-inquiry.action';
import { OrderMoneyModel } from '@app/models/myoffice/order/order-money.model';
import { DateUtils } from '@app/common/util/date.util';
import { TranslateService } from '@ngx-translate/core';
import { WownetWordModel } from '@app/models/system/wownet-word.model';
import { SmWownetConfigModel } from '@app/models/system/sm-wownet-config.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.css']
})
export class OrderReportComponent implements OnInit {
  userid: string = "";
  orderNo: string = "";

  birthDay:string  = '';
  today:string  = '';
  totalDone: number = 0;
  isMember: boolean = false;
  isShipping: boolean = false;
  haveReceipt: boolean = false;
  cardApp: boolean = false;

  company$ = new Observable<Company>;
  company: Company = {} as Company;

  orderProducts$ = new Observable<OrderProductReportModel[]>;
  orderProducts: OrderProductReportModel[] = [];

  orderMst$ = new Observable<OrderMstModel>;
  orderMst: OrderMstModel = {} as OrderMstModel;

  memInfo$ = new Observable<MemInfo>;
  memInfo: MemInfo = {} as MemInfo;

  orderInfos$ = new Observable<OrderMstModel[]>;
  orderInfo: OrderMstModel = {} as OrderMstModel;

  smWownet$ = new Observable<SmWownetModel>;
  smWownet: SmWownetModel = {} as SmWownetModel;

  lang : string =  CommonUtils.getLang();
  orderProduct2s$ = new Observable<OrderPdtModel[]>;
  sumOrderProducts$ = new Observable<OrderPdtModel>;
  product2s:OrderPdtModel[] = [];
  sumproduct2 = {} as OrderPdtModel;
  orderMoneys$ = new Observable<OrderMoneyModel[]>;
  orderMoneyCash ={} as OrderMoneyModel;
  orderMoneyBankbook ={} as OrderMoneyModel;
  orderMoneyCard ={} as OrderMoneyModel;
  orderInfos2$ = new Observable<OrderMstModel[]>();
  shippingInfo: OrderMstModel = {} as OrderMstModel;

  orderDeilis$ = new Observable<OrderMstModel[]>();
  orderDeilis: OrderMstModel[] = [];
  rows: number[] = [];
  num: number =2;
  isPv1: boolean = false;
  isPv2: boolean = false;
  isPv3: boolean = false;
  smConfig$ = new Observable<SmWownetConfigModel>();

  strYear: string = this._translate.instant("year");
  strMonth: string = this._translate.instant("month");
  strDay: string = this._translate.instant("day");
  smWowWord: any = {} as WownetWordModel;
  smWowWord$ = new Observable<WownetWordModel>();

  sumPdtQty: number = 0;
  constructor(
    private _orderDetailInquiryStore: Store<OrderDetailInquiryState>,
    private _activatedRoute: ActivatedRoute,
    private _companyStore: Store<CompanyState>,
    private _orderReportStore: Store<OrderReportState>,
    private _memberInfoStore: Store<MemberInfoInquiryState>,
    private _smWownetStore: Store<SmWownetState>,
    private _translate :TranslateService,
    private _titleService : Title
  ) {
    this.orderProduct2s$ = this._orderDetailInquiryStore.select(getOrderProductInfo);
    this.sumOrderProducts$ = this._orderDetailInquiryStore.select(sumOrderProductInfo);
    this.company$ = this._companyStore.select(getCompany);
    this.orderProducts$ = this._orderReportStore.select(getOrderProductsReport);
    this.orderMst$ = this._orderReportStore.select(getOrderMstReport);
    this.orderInfos$ = this._orderReportStore.select(getOrderInfosReport);
    this.memInfo$ = this._memberInfoStore.select(getMemInfo);
    this.smWownet$ = this._smWownetStore.select(getSmWownet);
    this.smConfig$ = this._smWownetStore.select(getSmWownetConfig);
    this.orderMoneys$ = this._orderDetailInquiryStore.select(getOrderPayInfo);
    this.orderInfos2$ = this._orderDetailInquiryStore.select(getOrderInfo);
    this.orderDeilis$ = this._orderDetailInquiryStore.select(getOrderDelis);
    this.smWowWord$ = this._smWownetStore.select(getWowWord);

  }

  ngOnInit(): void {
    this._smWownetStore.dispatch(loadSmWownetConfig());
    this._smWownetStore.dispatch(loadWowWord());
    this.today = DateUtils.getCurrentDate(true, '', 'KR')
    this._activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.userid = ConvertUtil.convertToString(params.get("userid"));
      this.orderNo = ConvertUtil.convertToString(params.get("orderNo"));

      if (ValidationUtil.isNotNullAndNotEmpty(this.userid) && ValidationUtil.isNotNullAndNotEmpty(this.orderNo)) {
        this._titleService.setTitle(this.orderNo);
        this._companyStore.dispatch(loadCompany());
        this._orderReportStore.dispatch(loadOrderProductReport({ ordNo: this.orderNo }));
        this._orderReportStore.dispatch(loadOrderMstReport({ ordNo: this.orderNo }));
        this._orderReportStore.dispatch(loadOrderInfoReport({ params: {comId: environment.comId, lang: environment.default_lang, ordNo: this.orderNo} }));
        this._memberInfoStore.dispatch(loadMemInfo(this.userid));
        this._smWownetStore.dispatch(loadSmWownet());
        this._orderDetailInquiryStore.dispatch(loadDeliOrd({ orderNo: this.orderNo }));

        // this._orderDetailInquiryStore.dispatch(loadOrderInfo({ params: params }));
        this._orderDetailInquiryStore.dispatch(loadOrderProduct({ orderNo: this.orderNo }));
        this._orderDetailInquiryStore.dispatch(loadSumOrderProduct({ orderNo: this.orderNo }))

        let params2 = {
          comId: environment.comId,
          lang: environment.default_lang,
          ordNo: this.orderNo,
          userId: this.userid,
        }
        this._orderDetailInquiryStore.dispatch(loadOrderPay({ params: params2 }));
      }
    });

    this.smConfig$.subscribe(res => {
      if (ValidationUtil.isNotNullAndNotEmpty(res)) {
        this.isPv1 = res.oUsePv1 === "Y";
        this.isPv2 = res.oUsePv2 === "Y";
        this.isPv3 = res.oUsePv3 === "Y";
        if(this.isPv1){
          this.num++;
        }
        if(this.isPv2){
          this.num++;
        }
        if(this.isPv3){
          this.num++;
        }
      }
    });
    this.smWowWord$.subscribe(res => this.smWowWord = res);

    this.company$.subscribe(res => {
      if (ValidationUtil.isNotNullAndNotEmpty(res)) {
        this.company = res;
        this.print();
      }
    });

    this.orderProducts$.subscribe(res => {
      if (ValidationUtil.isNotNullAndNotEmpty(res)) {
        this.orderProducts = res;
        this.print();

        for(let i=0; i < this.orderProducts.length; i++){
          this.sumPdtQty += +this.orderProducts[i].qty;
        }
      }
    });

    this.orderMst$.subscribe(res => {
      if (ValidationUtil.isNotNullAndNotEmpty(res)) {
        this.orderMst = res;
        this.print();
      }
    });

    this.orderInfos$.subscribe(res => {
      if (ValidationUtil.isNotNullAndNotEmpty(res)) {

        this.orderInfo = res[res.length - 1];
        this.isShipping = this.orderInfo.deliKind.includes("DELI");
        this.haveReceipt = this.orderInfo.rcptYN != 'Non-Deposit';

        this.print();
      }
    });

    this.memInfo$.subscribe(res => {
      if (ValidationUtil.isNotNullAndNotEmpty(res)) {
        this.memInfo = res;
        this.isMember = !res.userKind.endsWith("U00")
        if(ValidationUtil.isNotNullAndNotEmpty(res.birthday1) ){
          this.birthDay = res.birthday1.substring(0, 4) + this.strYear + res.birthday1.substring(4, 6) + this.strMonth + res.birthday1.substring(6, 8) + this.strDay;
        }
        this.print();
      }
    });

    this.smWownet$.subscribe(res => {
      if (ValidationUtil.isNotNullAndNotEmpty(res)) {
        this.smWownet = res;
        this.print();
      }
    });

    this.orderProduct2s$.subscribe(res => {
      if (ValidationUtil.isNotNullAndNotEmpty(res)) {
        this.product2s = res;
      } else {
        this.product2s = [];
      }
    });
    this.sumOrderProducts$.subscribe(res => {
      if (ValidationUtil.isNotNullAndNotEmpty(res)) {
        this.sumproduct2 = res;
      } else {
        this.sumproduct2 = {} as OrderPdtModel;
      }
    })

    this.orderMoneys$.subscribe(res => {
      if (ValidationUtil.isNotNullAndNotEmpty(res)) {
        res.forEach(item=>{
          if(item.kind.endsWith("01")){
            this.orderMoneyCash = item;
          }else if(item.kind.endsWith("02")){
            this.orderMoneyCard = item;
            this.cardApp = (ValidationUtil.isNotNullAndNotEmpty(item.cardInstall)&& Number(item.cardInstall)>0)
          }else if(item.kind.endsWith("03")){
            this.orderMoneyBankbook = item;
          }
        });
      }
    });
    this.rows = new Array(10);
  }

  print(): void {
    if (ValidationUtil.isNullOrEmpty(this.company.comId)) return;
    if (ValidationUtil.isNullOrEmpty(this.orderProducts)) return;
    if (ValidationUtil.isNullOrEmpty(this.orderMst.ordNo)) return;
    if (ValidationUtil.isNullOrEmpty(this.orderInfo.ordNo)) return;
    if (ValidationUtil.isNullOrEmpty(this.memInfo.userid)) return;
    if (ValidationUtil.isNullOrEmpty(this.smWownet.comId)) return;
    setTimeout(() => window.print(), 500);
  }

  formatOrderDate(orderDate: any): string {
    const year = parseInt(orderDate.substring(0, 4), 10);
    const month = parseInt(orderDate.substring(4, 6), 10) - 1; // Tháng là zero-indexed
    const day = parseInt(orderDate.substring(6, 8), 10);


    // Create a new Date object
    const date = new Date(year, month, day);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dayR = ("0" + date.getDate()).slice(-2);
    const monthR = monthNames[date.getMonth()];
    const yearR = date.getFullYear();

    return `${monthR}. ${dayR}, ${yearR}`;
  }
}
