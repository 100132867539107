import { SelectDropdownModel } from "@app/models/components/select-dropdown.model";
import { TranslateService } from "@ngx-translate/core";
import { ConvertUtil } from "./convert.util";

export class DateUtils {
  static getCurrentDate(withName:boolean, kind:string = '', lang : string = ''){
    var now: Date = new Date();
    let y = now.getFullYear();
    let m = ('0' + (now.getMonth()+1)).slice(-2);
    let d = ('0' + (now.getDate())).slice(-2);

    if(lang == 'kr' || lang == 'KR') {
      if(kind == 'y'){
        return withName?`${y}년`:`${y}`
      }else if(kind == 'm'){
        return withName?`${m}월`:`${m}`
      }else if(kind == 'd'){
        return withName?`${d}일`:`${d}`
      }
      return withName?`${y}년 ${m}월 ${d}일`:`${y}${m}${d}`
    } else {
      if(kind == 'y'){
        return withName?`${y}-`:`${y}`
      }else if(kind == 'm'){
        return withName?`${m}-`:`${m}`
      }else if(kind == 'd'){
        return withName?`${d}-`:`${d}`
      }
      return withName?`${y}-${m}-${d}`:`${y}${m}${d}`
    }

    
  }
  static getCurrentDateDash (){
    var now: Date = new Date();
    let y = now.getFullYear();
    let m = ('0' + (now.getMonth()+1)).slice(-2);
    let d = ('0' + (now.getDate())).slice(-2);

    return`${m}/${d}/${y}`;
  }

  static getCurrFullDateTimeStr(){
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = ('0' + (today.getMonth()+1)).slice(-2);
    let dd = ('0' + (today.getDate())).slice(-2);

    let hh = today.getHours()
    let min = ('0' + (today.getMinutes())).slice(-2);
    let ss = ('0' + (today.getSeconds())).slice(-2);

    return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`
  }

  static getDateGlobal(date: Date, lang: string): string {
    if (lang.toUpperCase() === "KR") {
      return date.getFullYear() + "-" + ConvertUtil.convertToZeroDecimal(date.getMonth() + 1) + "-" + ConvertUtil.convertToZeroDecimal(date.getDate());
    } else {
      return date.getFullYear() + "" + ConvertUtil.convertToZeroDecimal(date.getMonth() + 1) + "" + ConvertUtil.convertToZeroDecimal(date.getDate());
    }
  } 

  static getListTimeSelect(_translateService: TranslateService): SelectDropdownModel[] {
    return [
      {label: "Today", value: "0"},
      {label: _translateService.instant("common.010"), value: "1"},
      {label: _translateService.instant("common.011"), value: "2"},
      {label: _translateService.instant("common.012"), value: "3"},
      {label: _translateService.instant("common.022"), value: "4"},
    ];
  }

  static splitDateUS(date : string) {

    if(date.includes(".")) {

      const myArrayDate = date.split(".");
      let newDate = myArrayDate[2] + "." + myArrayDate[0] + "." + myArrayDate[1];
      
      return newDate;
    }

    return date;
  }

}